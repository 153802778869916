export default {
  title: 'Data Observatory',
  subtitle: 'Spatial Data Catalog',
  loading: 'Loading catalog...',
  showFilters: 'Show filters',
  resultsCount: {
    pagination: '{offset}-{limit}',
    plural: '{pagination} of {count} results',
    singular: '1 result'
  },
  search: {
    placeholder: 'Search data product',
    searchFor: 'Search for',
    cancel: 'Cancel',
    enterPress: 'Enter ↵',
    dataProductsCount: '{datasetCount} data products',
    datasetList: {
      title: 'Popular data products'
    },
    providerList: {
      title: 'Top providers'
    },
    categoryList: {
      title: 'Featured categories'
    }
  },
  filter: {
    globalDatasetsAlert: 'You can find {count} more data products in the Global option.',
    order: {
      rank: 'Featured',
      mostRecent: 'Most recent',
      az: 'A-Z'
    },
    subscription: {
      all: 'All',
      subscribed: 'Subscribed',
      requested: 'Requested'
    },
    widget: {
      defaultTitle: 'Filters',
      clearAll: 'Clear',
      hideFilters: 'Hide filters',
      singleFilterSelected: '1 filter',
      multipleFiltersSelected: '{nbr} filters',
      noResultsSuggestion: "Try adjusting your search to find what you're looking for.",
      countries: {
        title: 'Countries',
        placeholder: 'Find a country',
        noResults: 'No countries for your search',
        nbSelectedElements: '{nbr} countries'
      },
      categories: {
        title: 'Categories',
        placeholder: 'Find a category',
        noResults: 'No categories for your search',
        nbSelectedElements: '{nbr} categories'
      },
      sources: {
        title: 'Sources',
        placeholder: 'Find a source',
        noResults: 'No source for your search',
        nbSelectedElements: '{nbr} sources'
      },
      providers: {
        title: 'Providers',
        placeholder: 'Find a provider',
        noResults: 'No providers for your search',
        nbSelectedElements: '{nbr} providers'
      },
      useCases: {
        title: 'Use cases',
        placeholder: 'Find a use case',
        noResults: 'No use cases for your search',
        nbSelectedElements: '{nbr} use cases'
      },
      temporalAggs: {
        title: 'Temporal aggregations',
        placeholder: 'Find a temporal aggregation',
        noResults: 'No temporal aggregations for your search',
        nbSelectedElements: '{nbr} temporal aggregations'
      },
      spatialAggs: {
        title: 'Spatial aggregations',
        placeholder: 'Find a spatial aggregation',
        noResults: 'No spatial aggregations for your search',
        nbSelectedElements: '{nbr} spatial aggregations'
      },
      licenses: {
        title: 'Licenses',
        placeholder: 'Find a license',
        noResults: 'No licenses for your search',
        nbSelectedElements: '{nbr} licenses'
      }
    }
  },
  commons: {
    browseHome: 'Return to home'
  },
  home: {
    categories: 'Categories',
    providers: 'Providers',
    recentsDatasets: 'Recently added',
    popularDatasets: 'Most popular',
    browseLink: 'Browse the catalog',
    footer: {
      body: 'This project has received funding from the {link} research and innovation programme.',
      link: "European Union's Horizon 2020",
      agreement: 'Grant Agreement',
      numberAgreement: 'No 960401'
    }
  },
  card: {
    from: 'From',
    sample: 'Free sample',
    category: {
      caption: '{elements} data products'
    }
  },
  browser: {
    searchResults: 'Data products for "{fullTextSearch}"',
    dataProducts: 'Data products',
    clearFilters: 'Clear filters',
    noResults: {
      title: 'No data products were found',
      subtitle: "Try adjusting your search to find the products you're looking for or clear your filters.",
      stillSearch: "Still can't find what you’re looking for?",
      requestInfo: 'Get help from our team'
    }
  },
  productFile: {
    documentationLink: 'More info on the product documentation',
    frequentlyQuestions: 'Frequently Asked Questions',
    header: {
      requestSubscription: 'Request subscription',
      publicRequestSubscription: "I'm interested"
    },
    tabs: {
      details: 'Summary',
      variables: 'Data',
      faqs: 'FAQs'
    },
    variables: {
      noDataSample: 'Data preview not available.',
      thereSampleFor: ' This one is for {dataset}',
      dataSample: 'Data sample',
      limitedResults: 'Preview limited to 10 rows',
      dataDictionary: 'Data dictionary',
      countInfoRows: '{value} rows',
      countInfoColumns: '{value} columns',
      columnName: 'Column name',
      type: 'Type',
      total: 'Total',
      description: 'Description'
    },
    details: {
      showAllUseCases: 'See all use cases',
      productDescription: 'Product description',
      providerWebsite: 'Provider website',
      viewProduct: 'View product',
      useCases: 'Use-cases',
      licenseLink: 'Terms and conditions',
      boughtTogetherProducts: 'Products bought together',
      moreProviderProducts: 'More products from {providerName}',
      moreProviderProductsNoData: 'More products',
      viewAllProviderDatasets: 'All products from this provider',
      title: 'Details',
      viewAllVariables: 'See all variables',
      category: 'Category',
      license: 'License',
      keyVariables: 'Key variables',
      provider: 'Provider',
      country: 'Country',
      placeType: 'Place type',
      spatialAggregation: 'Spatial aggregations',
      temporalAggregation: 'Temporal aggregation',
      updateFrequency: 'Update frequency',
      geographyName: 'Associated geography'
    }
  },
  actions: {
    subscribeFree: 'Subscribe for free',
    subscribeSample: 'Access free sample',
    subscribePremium: 'Request subscription',
    subscriptionRequested: 'Cancel request',
    alreadySubscribed: 'View sample',
    accessData: 'Access data',
    dismiss: 'Dismiss'
  },
  subscription: {
    status: {
      subscribed: 'Subscribed',
      requested: 'Requested',
      cancel: 'Cancel',
      expiresSoon: 'Expires soon',
      tooltip: {
        subscribed: 'Go to Subscriptions in Settings',
        requested:
          'A CARTO team member will get in touch to give you more information and go over any questions you may have.',
        expiresSoon: 'For more info contact with your organization’s administrator.'
      }
    },
    expiresAt: '{expirationLeft} days left',
    title: 'Confirm your subscription',
    subtitle: "You're going to subscribe to the following dataset",
    terms: 'I accept the License',
    connect: 'Confirm subscription',
    request: {
      modalTitle: 'Request subscription',
      title: 'Confirm your request',
      subtitle: "You're going to request a subscription for the following dataset",
      info: 'Once you confirm your request, a CARTO team member will get in touch to give you more information and go over any questions you may have.',
      confirm: 'Confirm request'
    },
    cancel: {
      modalTitle: 'Cancel request',
      content: 'Are you sure you want to cancel the subscription request for data {datasetName}?',
      reminder: 'You are not subscribed yet. You can request it again later.',
      keep: 'Keep the request',
      confirm: 'Yes, cancel'
    },
    alert: {
      public: 'Public',
      premium: 'Premium',
      sample: 'You have a sample of this dataset available in the Data Explorer.',
      active: 'Active subscription',
      subscribed: 'You have an {active} to this {type} dataset.',
      requested:
        'You have requested a subscription to this dataset. A CARTO team member will get in touch to give you more information.',
      dismiss: 'Changed your mind? <a>Dismiss it.</a>'
    },
    dismiss: {
      title: 'Dismiss request',
      message: 'Are you sure you want to cancel the subscription request? You can request it again at any time.',
      confirm: 'Yes, dismiss',
      cancel: 'No, keep it'
    }
  },
  accessSample: {
    modalTitle: 'Access free sample',
    title: 'Connect your sample',
    subtitle: "You're going to access the following sample dataset:",
    termsUseTitle: 'Terms of use',
    disclaimer:
      'The sample data is for trial evaluation purposes only and may differ slightly from product data. It may be used internally only for non-commercial purposes. This sample is subject to limited, non-exclusive, non-transferable, non-sublicensable and revocable rights and license to use. Any rights not expressly granted are withheld.',
    terms: 'I accept the Terms of Use',
    connect: 'Connect sample',
    accessSample: 'Access sample',
    sampleNotAvailable: 'Sample not available: this sample is for {dataset}',
    somethingWentWrong: 'Something went wrong'
  }
}
