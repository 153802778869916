export default {
  form: {
    optional: '(Optional)',
    select: 'Select',
    multiEmailsTextField: {
      errors: {
        label: 'Errors in emails',
        invalid: 'Some emails are invalid, please check above',
        duplicated: 'Some emails are duplicated, please check above'
      }
    }
  },
  errors: {
    title: 'Error {code}',
    back: 'Back to home',
    404: {
      title: 'Page not found',
      subtitle: 'The page you requested could not be found.'
    },
    500: {
      title: 'Internal Server Error',
      subtitle: 'The server encountered an internal error and was unable to complete your request.'
    },
    other: {
      contact: 'Please, {link} if the problem persists.',
      linkLabel: 'contact us'
    },
    syntaxError: 'Syntax error'
  },
  menu: {
    home: 'Home',
    maps: 'Maps',
    workflows: 'Workflows',
    applications: 'Applications',
    developers: 'Developers',
    connections: 'Connections',
    dataExplorer: 'Data Explorer',
    dataObservatory: 'Data Observatory',
    spatialDataCatalog: 'Spatial Data Catalog',
    accountSettings: 'Settings',
    inviteUsers: 'Invite users',
    documentation: 'Documentation',
    beta: 'Beta'
  },
  actions: {
    delete: 'Delete',
    edit: 'Edit',
    share: 'Share',
    readView: 'Read view',
    permissionsAndSharing: 'Permissions and sharing'
  },
  button: {
    save: 'Save changes',
    saveForm: 'Save',
    cancel: 'Cancel',
    discard: 'Discard',
    confirm: 'Confirm',
    next: 'Next',
    continue: 'Continue',
    create: 'Create',
    back: 'Back',
    clear: 'Clear',
    viewDetails: 'View details',
    refresh: 'Refresh',
    close: 'Close',
    change: 'Change',
    done: 'Done',
    download: 'Download',
    copy: 'Copy',
    copyFQN: 'Copy qualified name',
    copied: 'Copied',
    copiedToClipboard: 'Copied to clipboard',
    run: 'Run',
    runningTime: 'Running Time',
    ok: 'Ok',
    okRun: 'Ok, run',
    copyLink: 'Copy link',
    wantToBeContacted: 'I want to be contacted',
    contactRequested: 'Contact requested',
    notNow: 'Not now, thanks',
    import: 'Import',
    minimize: 'Minimize',
    tryAgain: 'Try again',
    contactUs: 'Contact us',
    delete: 'Delete',
    yesDelete: 'Yes, delete',
    request: 'Request',
    sendingRequest: 'Sending request...',
    moreInfo: 'More info',
    readMore: 'Read more',
    readLess: 'Read less',
    readEntireMessage: 'Read entire message',
    apply: 'Apply',
    reset: 'Reset',
    resetToDefault: 'Reset to default',
    upgrade: 'Upgrade',
    previousPage: 'Previous page',
    add: 'Add',
    added: 'Added',
    showMore: 'Show more',
    showLess: 'Show less',
    checkDocumentation: 'Check documentation',
    viewDocumentation: 'View documentation',
    connect: 'Connect',
    reconnect: 'Reconnect',
    stop: 'Stop',
    okGoHome: 'Ok, take me Home',
    export: 'Export',
    learnMore: 'Learn more',
    login: 'Login',
    signup: 'Sign Up',
    allow: 'Allow',
    retry: 'Retry',
    send: 'Send',
    show: 'Show',
    hide: 'Hide',
    expand: 'Expand',
    keepEditing: 'Keep editing'
  },
  utils: {
    loader: {
      label: 'Loading...'
    },
    emptySection: {
      title: 'This main feature will be released soon',
      content: '{contactLink} for more information',
      contactLink: 'Contact us'
    },
    notifications: {
      notAvailable: {
        title: 'Notifications are disabled',
        content: 'We will release this feature soon'
      }
    },
    importDataFile: 'Import data from file',
    importDataFileList: 'Shapefiles, GeoPackage, CSV, KML, KMZ, TAB, GeoJSON and (Geo)Parquet files are supported ',
    dontShowAgain: 'Don’t show again',
    contactSupport: 'For more information, please contact CARTO’s support team at {contactLink}.'
  },
  cards: {
    mapInfo: {
      zoom: 'Zoom',
      properties: 'PROPERTIES',
      featuresCount: 'FEATURES COUNT'
    },
    createdByOwner: 'Created by {owner}',
    lastUpdatedTooltip: 'Last updated',
    table: 'Table',
    tileset: 'Tileset',
    sharing: {
      organization: 'Shared with Organization'
    }
  },
  pagination: {
    elements: '{first}-{last} of {total}',
    pageCount: '<strong>{from}-{to}</strong> of {count}',
    rowsPerPage: 'Rows per page'
  },
  logout: {
    account: 'Organization name',
    logoutButton: 'Logout'
  },
  header: {
    upgraded: 'Your plan was upgraded to Starter',
    expiration: {
      expiresIn: 'Your plan will expire in {days} days',
      expired: 'Your plan has expired',
      renew: 'Renew your plan',
      upgrade: 'Upgrade',
      moreInfo: 'More info',
      modal: {
        1: {
          expired: 'Your plan expired on {date}',
          expiresIn: 'Your plan will expire on {date}.'
        },
        2: 'To continue using CARTO you must renew or upgrade your current plan.',
        3: {
          expired: 'In the next {days} days',
          expiresIn: 'After this',
          main: '{when}, all your created maps and imported datasets will be lost. For more information on how to renew your plan, click the button below.'
        }
      }
    }
  },
  shareDialog: {
    title: 'Sharing options',
    publicOption: 'Public',
    publicDesc: 'Anyone with the link can view',
    privateOption: 'Private',
    privateDesc: 'Only you can view and edit',
    publishedMapSettings: {
      title: 'Shared map settings'
    },
    settingsOnlySharedMap: 'Settings available only for shared maps',
    sharedOption: 'Organization',
    sharedDesc: `Users at {account} can view`,
    sharedDescShort: `Users at your organization can view`,
    sharedDescUsers: `{users} {users, plural,
          one {user}
          other {users}
      }`,
    shareWithAll: {
      option: 'All users'
    },
    sharingMode: {
      title: 'Share mode',
      all: 'Entire organization {users}',
      groups: 'Specific groups',
      viewerCredentials: 'Viewer credentials'
    },
    tooltip: {
      singleAdmin:
        "If there's only one Admins group, it is not possible to manage its role, to avoid your organization left out with no admin users.",
      serviceAccount:
        'BigQuery connections using a Service Account are not compatible with requiring viewer credentials.',
      viewerCredentials: 'This resource cannot be shared with groups as viewer credentials are needed to access it'
    },
    shareWithGroups: {
      switchLabel: 'Share only with specific groups',
      clearAll: 'Clear all',
      noSelected: 'No groups selected',
      placeholder: 'Groups',
      members: 'members',
      submitInfo: 'Please select at least one group to share your map with',
      submitInfoConn: 'Please select at least one group to share your connection with',
      submitInfoApp: 'Please select at least one group to share your application with',
      notFound: 'No groups matching your search criteria',
      description: '{groups} at {account} can view',
      descriptionGroups: `{groups} {groups, plural,
          one {group}
          other {groups}
      }`,
      info: {
        notAvailable:
          "This {resource} is shared with all users in your organization. If you're interested in sharing with certain users or groups, <link>let us know</link>.",
        noGroups:
          "There are no groups to share with. Groups are synchronized using your company's SSO info every time a user logs in. <link>Read more</link>."
      },
      option: '{groups} groups'
    },
    mapSettings: {
      viewers: {
        sectionTitle: 'Settings for viewers'
      },
      collaboration: {
        sectionTitle: 'Collaboration',
        collaborative: 'If enabled, other editors in your organization will be able to edit this map.',
        privateConn: `One or more connections used in this map are not shared with the other editors.`,
        privateConnEnable: `To enable collaboration <a>set your connections to shared</a>.`,
        privateConnDisableOwner: `This map will not work properly for collaborators.
           Please either <a>set your connections to shared</a> or disable collaboration on this map.`,
        privateConnAskOwner: `Please contact owner to share required connections to use this map.`
      }
    },
    default: '(default)',
    save: 'Save',
    mapSettingsUnavailable: 'Open the map in Builder to access these settings.'
  },
  goto: {
    settings: 'Go to Settings',
    maps: 'Go to Maps',
    connections: 'Go to Connections',
    dataExplorer: 'Go to Data Explorer'
  },
  period: {
    month: 'month',
    monthly: 'Monthly',
    yearly: 'Yearly'
  },
  others: 'others',
  shareOnFacebook: 'Share on Facebook',
  shareOnLinkedin: 'Share on LinkedIn',
  shareOnTwitter: 'Share on Twitter',
  copyError: 'An error occurred while copying the text. Please try again.',
  orders: {
    lastAdded: 'Last added',
    lastUpdated: 'Last updated',
    lastInvited: 'Last invited',
    lastRequested: 'Last requested',
    name: 'Name'
  },
  contact: {
    title: 'Confirm your request',
    message:
      'After confirming, a CARTO team member will get in touch shortly to guide you through the process of enabling {feature}.'
  },
  dataPreview: {
    title: 'Data Preview',
    error: 'Data preview could not be displayed',
    errorAction: 'Get more details',
    columns: 'Preview limited to {limit} columns'
  },
  documentation: 'documentation',
  date: {
    invalidFormat: 'Provide date in {format} format.'
  },
  yes: 'Yes',
  no: 'No',
  to: 'to',
  min: 'Min',
  max: 'Max',
  workspace: 'Workspace',
  notifications: {
    errorDetails: 'Error details',
    changesSaved: 'Changes saved successfully',
    changesErrorSaving: 'Something went wrong while saving your changes. Please try again.'
  },
  markdown: {
    helperText: 'Markdown formatting supported. Learn more in this <docLink>Documentation</docLink>.'
  },
  or: 'Or',
  type: 'Type',
  by: 'by',
  duration: {
    hours: '{time} hours',
    minutes: '{time} min.',
    seconds: '{time} sec.'
  },
  drawFeatures: {
    tool: '{label} tool',
    clickToEdit: 'Click on the map to edit',
    clickToDraw: 'Click on the map to draw a {label}',
    chooseTool: 'Choose a tool',
    chooseDrawingTool: 'Choose a drawing tool',
    center: 'Center map on feature'
  },
  dialogConfirmation: {
    title: 'Are you sure you want to leave?',
    content: 'The changes you made will not be saved.',
    leave: 'Leave'
  }
}
